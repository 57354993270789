/* @flow */

import firebase from 'firebase/compat/app';

import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

import { documentId } from 'firebase/firestore';

import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';

import { v4 as uuidv4 } from 'uuid';

const firebaseConfig = {
  apiKey: "AIzaSyAEHXyEc2HUIWtahPuztG_2NeE_Pr8vYQc",
  authDomain: "marketbol.firebaseapp.com",
  databaseURL: "https://marketbol.firebaseio.com",
  projectId: "marketbol",
  storageBucket: "marketbol.appspot.com",
  messagingSenderId: "1047968939013",
  appId: "1:1047968939013:web:f5d5ef10424e485d61306c"
};
const app = firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();
const firestore = firebase.firestore();
const storageRef = storage.ref();

window.storage = storage;
window.firebase = firebase;
window.documentId = documentId;

export const auth = firebase.auth();
auth.languageCode = 'es';

window.auth = auth;
// auth.settings.appVerificationDisabledForTesting = true;

const adminsObj = {
  'h22UPxvzsxdfoR6Eznx8pD0As8h1': '+59178917002',
  'QlntQRKzBRfrGKbKwwwgZt9bqZw2': '+59178917002',
};

export function processSnapshotsArr(list: Array<Object>): Array<Object> {
  const res = [];
  list.forEach((doc: Object) => {
    res.push({
      id: doc.id,
      data: { ...doc.data() },
    });
  });
  return res;
}

export function onAuthStateChanged(cb: Function) {
  auth.onAuthStateChanged(async function(user) {
    if (user) {
      if (adminsObj[user.uid]) {
        window.adminMode = true;
        window.adminModePhone = adminsObj[user.uid];
      }
      return await firestore.collection('authors')
        .doc(user.uid)
        .get()
        .then((doc) => {
          let userRes = { uid: user.uid };
          if (doc.exists && user.uid) {
            userRes = { uid: user.uid, ...doc.data() };
          }
          window.userRes = userRes;
          cb(userRes);
        });
    }
  });
}

export function requestSaveAuthorData(userUid: string, props: Object) {
  return firestore.collection('authors')
    .doc(userUid)
    .set(props, { merge:true });
}

export function createUserWithEmailAndPassword(email: string, password: string) {
  return auth.createUserWithEmailAndPassword(email, password)
    .catch(function(error) {
      throw error;
    });
}

export function signInWithEmailAndPassword(email: string, password: string) {
  return auth.signInWithEmailAndPassword(email, password)
    .catch(function(error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
      throw error;
    });
}

export function signOutAuth() {
  return auth.signOut()
    .catch(function(error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
      throw error;
    });
}

export async function requestGetCategories() {
  return await firestore.collection('categories').get();
}

export async function requestGetCities() {
  return await firestore.collection('cities').get();
}

export function uploadFirebaseImage(file: Object, props: Object) {
  const id = uuidv4();
  const imagesRef = storageRef.child(`${props.folder}/${id}`);
  const uploadTask = imagesRef.put(file);
  return new Promise(resolve => {
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (props.progressFn) props.progressFn(progress);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            break;
        }
      },
      (error) => { // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            break;
          case 'storage/canceled':
            break;
          case 'storage/unknown':
            break;
        }
      },
      () => {
        resolve(id);
      }
    );
  });
}

export async function requestCreateProductVisits(productId:string) {
  const productsRef = firestore.collection('product_visits');
  const productObj = await productsRef.doc(productId);
  return await productObj.set({
    count: 0,
  }, { merge: true });
}

export async function requestCreateProduct(props: Object) {
  try {
    const docRef = firestore.collection('products').doc();
    return firestore.runTransaction((transaction) => {
      const authorRef = firestore.collection('authors')
        .doc(window.auth.currentUser.uid);
      return transaction.get(authorRef).then((res) => {
        if (!res.exists) {
          transaction.set(authorRef, {
            phones: [props.phone],
          });
        } else {
          const userData = res.data();
          transaction.update(authorRef, {
            phones: [...(userData?.phones || []), props.phone],
          });
        }
        transaction.set(docRef, props);
        requestCreateProductVisits(docRef.id);
        return docRef;
      });
    });
  } catch (e) {
    console.log('Transaction failed:', e);
    return e;
  }
}

export async function requestUpdateProduct(props:Object) {
  const { productId, ...data } = props;
  const productsRef = firestore.collection('products');
  const productObj = await productsRef.doc(productId);
  return await productObj.set({
    ...props,
  }, { merge: true });
}

export function requestSaveProductVisit(productId:string, key:string = '') {
  const visitRef = firestore.collection('product_visits').doc(`${productId}`);
  const visitObj = {};
  if (key === 'whatsapp') {
    visitObj.whatsapp_count = firebase.firestore.FieldValue.increment(1);
  } else {
    visitObj.count = firebase.firestore.FieldValue.increment(1);
  }
  visitRef.update(visitObj);
}

export function requestSaveTags(
  tags:Array<string>,
  props:Object
):Promise<any> {
  return firestore.runTransaction((transaction) => {
    const arrPromises = [];
    tags.forEach((tag) => {
      const tagLower = tag
        .replaceAll(' ', '')
        .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
      const tagRef = firestore.collection('tags').doc(tagLower);
      arrPromises.push(
        transaction.get(tagRef).then((tagDoc) => {
          if (tagDoc.exists) {
            let newCount = tagDoc.data().count + 1;
            const list = tagDoc.data().list || [];
            if (props.productId) list.push(props.productId);
            transaction.update(tagRef, {
              count: newCount,
              list,
              last_date: new Date(),
            });
            return newCount;
          }
          const list = [];
          if (props.productId) list.push(props.productId);
          transaction.set(tagRef, {
            value: tag,
            list,
            count: 1,
            last_date: new Date(),
          });
          return 1;
        })
      );
    });
    return Promise.all(arrPromises);
  }).then((values) => {
  }).catch((err) => {
    // This will be an "population is too big" error.
    console.error(err);
  });
}
window.requestSaveTags = requestSaveTags;

export async function requestGetTags():Promise<any> {
  const tagsRef = firestore.collection('tags');
  return await tagsRef
    .orderBy('count', 'desc')
    .limit(50)
    .get();
}

export async function getProductsVisit(productIds:Array<string>):Promise<any> {
  return await firestore.collection('product_visits')
    .where(documentId(), 'in', productIds).get();
}

export async function requestGetProducts(
  props:Object,
  startAfterDate:any = null
):Promise<any> {
  const productsRef = firestore.collection('products');
  let productsQuery = productsRef;
  if (props && props.category != null) {
    productsQuery = productsQuery.where('category', '==', props.category);
  }
  if (props && props.city != null) {
    productsQuery = productsQuery.where('city', '==', props.city);
  }
  if (props && props.author != null) {
    productsQuery = productsQuery.where('author', '==', props.author);
  }
  return await productsQuery
    .orderBy('date', 'desc')
    .limit(15)
    .startAfter(startAfterDate || new Date())
    .get();
}

export async function requestGetProduct(props: Object) {
  const productsRef = firestore.collection('products');
  return await productsRef.doc(props.productId).get();
}

export function getImageUrl() {
  const str = 'gs://marketbol.appspot.com/original/watermar.png';
  window.storageRef = storageRef;

  return storageRef
    .child('original/watermar.png')
    .getDownloadURL()
    .then(function(url) {
    });
}

export async function requestGetAds(
  props:Object,
  startAfterDate:any = null
):Promise<any> {
  const adsRef = firestore.collection('ads');
  let adsQuery = adsRef;
  if (props && props.city != null) {
    adsQuery = adsQuery.where('city', '==', props.city);
  }
  const defaultDate = new Date();
  defaultDate.setHours(0,0,0,0);
  return await adsQuery
    .orderBy('date', 'asc')
    .limit(30)
    .startAfter(startAfterDate || defaultDate)
    .get();
}

export async function requestCreateAds(listNewAds: Object) {
  try {
    return firestore.runTransaction((transaction) => {
      // const authorRef = firestore.collection('authors')
      //   .doc(window.auth.currentUser.uid);
      // return transaction.get(authorRef).then((res) => {
      //   if (!res.exists) {
      //     transaction.set(authorRef, {
      //       phones: [props.phone],
      //     });
      //   } else {
      //     const userData = res.data();
      //     transaction.update(authorRef, {
      //       phones: [...(userData?.phones || []), props.phone],
      //     });
      //   }
      const res = [];
      listNewAds.forEach((props) => {
        const docRef = firestore.collection('ads').doc();
        transaction.set(docRef, props);
        console.log(docRef.id);
        res.push(docRef);
      });
      //   requestCreateProductVisits(docRef.id);
      return Promise.all(res);
      // });
    });
  } catch (e) {
    console.log('Transaction failed:', e);
    return e;
  }
}
