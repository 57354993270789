/* @flow */

import { fabric } from 'fabric';
import Notification from 'rsuite/Notification';
import {
  processSnapshotsArr,
  requestGetAds,
  requestCreateAds,
} from './client';
import {
  uploadImage,
} from './createProduct';

export function getAdsWall(): Function {
  return (dispatch: Function) => {
    return requestGetAds()
      .then((querySnapshot): Array<Object> => {
        const adsList = processSnapshotsArr(querySnapshot)
          .map((obj: Object) => ({
            ...obj,
            data: {
              ...obj.data,
              date: obj.data.date.toDate(),
            },
          }));
        return adsList.map((o) => {
          return {
            ...o,
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/marketbol.appspot.com/o/parties%2F${o.data.imageId}?alt=media`,
          };
        })
      });
  };
}

export function createGlobalAd(listNewAds:Array<Object>):Function {
  return (dispatch: Function) => {
    return requestCreateAds(listNewAds).then((res) => {
      console.log(res);
      return res;
    });
  };
}

export function handleUploadGlobalImageFile(
  image:Object,
  progressFn:Function = () => {}
): Promise<any> {
  return new Promise((resolve) => {
    function fnUploadImage(fileImage:Object) {
      const preview = URL.createObjectURL(fileImage);
      uploadImage(fileImage, {
        folder: 'parties',
        progressFn,
      }).then((newIdImage) => {
        resolve({
          id: newIdImage,
          image: fileImage,
          preview,
        });
      });
    }
    const canvas = new fabric.Canvas('canvasupload', {
      imageSmoothingEnabled: false,
      enableRetinaScaling: false,
    });
    fabric.Image.fromURL(URL.createObjectURL(image), (img) => {
      let imgWidth = img.width;
      let imgHeight = img.height;
      if (imgWidth <= 1200 && imgHeight <= 1200) {
        fnUploadImage(image);
      } else {
        const ratioSize = Math.min(1200 / imgWidth, 1200 / imgHeight);
        img.set({
          top: 0,
          left: 0,
          width: imgWidth,
          height: imgHeight,
          scaleX: ratioSize,
          scaleY: ratioSize,
        });
        window.img = img;
        canvas.setWidth(img.getScaledWidth());
        canvas.setHeight(img.getScaledHeight());
        canvas.add(img);
        canvas.renderAll();
        canvas.lowerCanvasEl.toBlob((canvasBlob) => {
          fnUploadImage(canvasBlob);
        }, 'image/jpeg', 0.95);
      }
    });
  });
}
