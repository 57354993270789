/* @flow */

import Notification from 'rsuite/Notification';
import * as firebase from 'firebase/app';
import {
  requestGetProducts,
  processSnapshotsArr,
  requestGetProduct,
  requestUpdateProduct,
  requestSaveProductVisit,
  getProductsVisit,
  requestGetTags,
} from './client';

import c from '../constants';

let lastQuery = {};
export function getProductsList(
  category:string,
  city:string,
  date:Object,
  author:any = null
):Function {
  let params:Object = {};
  if (category != null) params.category = category;
  if (city != null) params.city = city;
  if (author != null) params.author = author;
  return (dispatch: Function) => {
    return requestGetProducts(params, date)
      .then((querySnapshot): Array<Object> => {
        lastQuery = {
          category,
          city,
          author,
        };
        const newProducts = processSnapshotsArr(querySnapshot).map((obj: Object) => ({
          ...obj,
          data: {
            ...obj.data,
            date: obj.data.date.toDate(),
          },
        }));
        dispatch({
          type: author ? c.UPDATE_PRODUCTS_STATS : c.UPDATE_PRODUCTS,
          payload: {
            add: !!date,
            products: newProducts,
          },
        });
        return newProducts;
      });
  };
}

export function getTagsList():Function {
  return (dispatch: Function):Promise<any> => {
    return requestGetTags().then((querySnapshot) => {
      const newProducts = processSnapshotsArr(querySnapshot)
        .map((obj:Object) => {
          return {
            value: obj.data.value,
            label: obj.data.value,
          };
        });
      return newProducts;
    });
  };
}
window.getTagsList = getTagsList;

export function getProduct(
  productId: string,
):Function {
  return (dispatch: Function) => {
    return requestGetProduct({ productId }).then((doc) => {
      if (doc.exists) {
        return doc.data();
      }
      return { error: true };
    });
  };
}

export function updateProduct(productId:string, propsRaw: Object) {
  const props = {
    ...propsRaw,
    productId,
    date: new Date(),
  };
  requestUpdateProduct(props)
    .then(res => {
    })
    .catch((err) => {
      console.error(err);
    });
}

export function incVisitProduct(productId:string, key:string = '') {
  requestSaveProductVisit(productId, key);
}

export function visitProductGet(productIds:Array<string>):Promise<any> {
  if (productIds && productIds.length) {
    return getProductsVisit(productIds).then((querySnapshot) => {
      return processSnapshotsArr(querySnapshot);
    }).catch(err => console.error(err));
  }
  return Promise.resolve({});
}
