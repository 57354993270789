/* @flow */

import failures from "@react-native/codegen/lib/parsers/typescript/modules/__test_fixtures__/failures";

const c = {
  SET_USER: 1,
  UPDATE_USER: 2,
  UPDATE_PRODUCTS: 3,
  UPDATE_PRODUCTS_STATS: 4,
  SIGN_OUT: 5,
};

if (process.env.NODE_ENV === 'development') {
  Object.keys(c).forEach((k:string) => {
    c[k] = k;
  });
} else {
  Object.keys(c).forEach((k:string) => {
    c[k] = `${c[k]}`;
  });
}

export default c;
