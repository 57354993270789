/* @flow */

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from 'rsuite/Button';
import Dropdown from 'rsuite/Dropdown';
import Navbar from 'rsuite/Navbar';
import Nav from 'rsuite/Nav';
import Input from 'rsuite/Input';
import Avatar from 'rsuite/Avatar';

import UserIcon from '@rsuite/icons/legacy/User';
import {
  NavLink,
  useNavigate,
} from 'react-router-dom';

import './Account.css';
import {
  signOut,
  saveAccountData,
} from '../actions/account';

function Account(props: Object) {
  const [name, setName] = useState((
    props.user && props.user.name
  ) || '');

  let navigate = useNavigate();

  useEffect(() => {
    if (props.user) {
      setName(props.user.name);
    }
  }, [props.user]);
  
  const onSave = () => {
    props.saveAccountData(props.user.uid, {
      name,
    });
  };

  const onSignOut = () => {
    props.signOut();
    navigate(`/`);
  };

  return (
    <div className={classNames('mb-account', {})}>
      <div className="top-sec">
        <Avatar
          size="lg"
          circle
          alt={name}
        >
          <UserIcon />
        </Avatar>
        <div className="row">
          <label>{window.lc('Name')}</label>
          <Input
            value={name}
            onChange={setName}
            style={{ width: 300 }}
            placeholder={window.lc('Name')}
          />
        </div>
        <Button
          appearance="primary"
          onClick={onSave}
        >
          {window.lc('Save')}
        </Button>
      </div>
      <div className="bottom-sec">
        <Button
          onClick={onSignOut}
        >
          {window.lc('Sign Out')}
        </Button>
      </div>
    </div>
  );
}

export default (connect(
  ({ account }) => ({
    user: account.user,
  }),
  dispatch => bindActionCreators({
    signOut,
    saveAccountData,
  }, dispatch)
)(Account): any);
