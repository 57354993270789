/* @flow */

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Whisper from 'rsuite/Whisper';
import Tooltip from 'rsuite/Tooltip';

import './Parties.css';
import '../css/masonry.css';

import {
  getAdsWall,
} from '../actions/ads';

function PartiesComp(props:Object):Object {
  const [adsList, setAdsList] = useState([]);
  useEffect(() => {
    props.getAdsWall().then((res) => {
      setAdsList(res);
    });
  }, []);
  return (
    <div className="mb-parties">
      <div className="container">
        {adsList.map(o => (
          <Whisper
            placement="bottom"
            controlId="control-id-hover"
            trigger="hover"
            speaker={
              <Tooltip>
                {o.data.title}
              </Tooltip>
            }
          >
            <div
              className="figure"
              key={o.id}
            >
              <img
                src={o.imageUrl}
                alt="Fiesta"
              />
            </div>
          </Whisper>
        ))}
      </div>
    </div>
  )
}

export default (connect(
  ({ parties }) => ({
    parties,
  }),
  dispatch => bindActionCreators({
    getAdsWall,
  }, dispatch)
)(PartiesComp): any);
