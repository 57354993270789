/* @flow */

let PageName = 'Party Anuncios';

const localization = {
  'TituloPagina': PageName,
  'Home': PageName,
  'Parties': 'Fiestas',
  'Create Ad': 'Crear Anuncio',
  'Statistics': 'Estadisticas',
  'Account': 'Cuenta',
  'Enter': 'Ingresar',
  'Name': 'Nombre',
  'Save': 'Guardar',
  'Sign Out': 'Cerrar Sesion',
  'Select Tags': 'Elije los Servicios',
  'Select a Category': 'Elije una Categoria',
  'Select a City': 'Elije la Ciudad',
  'Zone': 'En que Zona atiendes',
  'Enter phone number': 'Numero Telefonico',
  'Upload Photos': 'Subir Fotos',
  'Delete': 'Elimiar',
  'Preview': 'Ver',
  'Cancel': 'Cancelar',
  'Ok': 'Continuar',
  'Description': 'Descripcion',
  'Title': 'Titulo',
  'Sign In': 'Ingresar',
  'Sign On': 'Crear Cuenta',
  'Welcome Back!': 'Bienvenido de vuelta!',
  'Sign In to {PageName}!': 'Ingresar en {PageName}!',
  'Required': 'Requerido',
  'Create Account': 'Crear Cuenta',
  'Create': 'Crear',
  'Hello, Friend!': 'Hola, Amigo!',
  'Verify': 'Verificar',
  'The email address is already in use.': 'El email ya esta en uso.',
  'The email address is not valid.': 'El email es invalido.',
  'Operation not allowed.': 'La operacion no esta permitida.',
  'The password is too weak.': 'El password es muy debil.',
  'Error': 'Error',
};

export function formatDateToSpanish(date:Object):string {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return 'Hoy';
  }
  if (date.toDateString() === yesterday.toDateString()) {
    return 'Ayer';
  }
  const monthsInSpanish = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];
  const month = monthsInSpanish[date.getMonth()];
  return `${date.getDate()} de ${month}`;
}

if (window.mobileCheck()) {
  // PageName = 'PAds';
  // localization['Home'] = 'PAds';
  // localization['Create Ad'] = 'Crear';
  // localization['Statistics'] = 'Info';
}
Object.keys(localization).forEach((k) => {
  localization[k] = localization[k].replaceAll('{PageName}', PageName);
});

window.lc = (key:string) => {
  return localization[key] || key;
};
