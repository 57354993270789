/* @flow */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import debounce from 'lodash/debounce';
import Modal from 'react-modal';

import Carousel from 'rsuite/Carousel';
import Dropdown from 'rsuite/Dropdown';
import Button from 'rsuite/Button';

import Mousetrap from 'mousetrap';
import { useParams, useNavigate } from 'react-router-dom';

import {
  getProduct,
  
  incVisitProduct,
} from '../actions/products';
import {
  formatDateToSpanish,
} from '../localized';
import {
  dataTagsServices,
  dataTagsAttention,
} from '../actions/createProduct';
import {
  getCitiesList,
} from '../actions/cities';

import './Ad.css';

const svgWhatsapp = (
  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"></path>
  </svg>
);

const getImgSrc = (imgId:string) => {
  return `https://firebasestorage.googleapis.com/v0/b/marketbol.appspot.com/o/original%2F${imgId}?alt=media`;
};

const whatsappMessage = 'Hola, acabo de ver tu anuncio en {PAGINA} y me gustaría mas información.';

function Ad(props:Object):Object {
  const [productData, setProductData] = useState(null);
  const [error, setError] = useState(null);
  const [currImage, setCurrImage] = useState(null);
  const [cities, setCities] = useState([]);
  let { productId } = useParams();
  let navigate = useNavigate();

  const onClickPrev = () => {
    let newIndex = currImage.imgIndex - 1;
    if (newIndex < 0) {
      newIndex = productData.images.length - 1;
    }
    onClickImage(newIndex);
  };
  const onClickNext = () => {
    let newIndex = currImage.imgIndex + 1;
    if (newIndex > productData.images.length - 1) {
      newIndex = 0;
    }
    onClickImage(newIndex);
  };
  const onClickClose = () => {
    setCurrImage(null);
  };
  const onClickEdit = () => {
    navigate(`/edit-ad/${productId}`);
  };

  useEffect(() => {
    props.getProduct(productId).then((res) => {
      window.gtag('event', 'screen_view', {
        'screen_name': productId,
        screen_author: res.author,
        screen_category: res.category,
        screen_city: res.city,
      });
      incVisitProduct(productId);
      if (res.error) {
        setError(res);
      } else {
        setProductData(res);
      }
    });
    props.getCitiesList().then((res) => {
      setCities(res);
    });
    return () => {
    };
  }, [productId]);

  useEffect(() => {
    if (currImage) {
      Mousetrap.bind('esc', onClickClose);
      Mousetrap.bind('left', onClickPrev);
      Mousetrap.bind('right', onClickNext);
    }
    return () => {
      Mousetrap.unbind(['esc', 'left', 'right']);
    };
  }, [currImage]);

  const onClickImage = (imgIndex) => {
    const imgId = productData.images[imgIndex];
    setCurrImage({
      imgId,
      imgSrc: getImgSrc(imgId),
      imgIndex,
    });
  };

  const onWhatsappClick = () => {
    incVisitProduct(productId, 'whatsapp');
    const win = window.open(
      `https://wa.me/${productData.phone}?text=${whatsappMessage
        .replaceAll('{PAGINA}', window.lc('TituloPagina'))
        .replaceAll('{TITULO}', productData.title)}`,
      '_blank'
    );
    win.focus();
  };

  if (!productData) {
    return null;
  }

  let cityStr = productData.city;
  if (cities) {
    cities.filter(o => {
      if (o.id === productData.city) {
        cityStr = o.data.name;
      }
    });
  }
  return (
    <div className="mb-single-ad">
      <div
        className="mb-preview-item"
      >
        {(props.user && productData.author === props.user.uid) ? (
          <Button
            appearance="primary"
            className="btn-edit"
            onClick={onClickEdit}
          >
            Edit
          </Button>
        ) : null}
        <div className="top-data">
          {formatDateToSpanish(new Date(productData.date.toDate()))}
        </div>
        <div className="content">
          <div className="content-left">
            <h2 className="title">{productData.title}</h2>
            <div className="city-zone">
              {cityStr}{productData.zone ? ` / ${productData.zone}` : null}
            </div>
            <p
              className="desc"
              dangerouslySetInnerHTML={{ __html: productData.desc }}
            ></p>
          </div>
          <div className="phone content-right">
            <Button
              appearance="ghost"
              onClick={() => {
                window.open(`tel:${productData.phone}`);
              }}
            >
              {productData.phone}
            </Button>
            <Button
              className="whatsapp"
              onClick={onWhatsappClick}
            >
              {svgWhatsapp} {productData.phone}
            </Button>
            {productData.noCostPublic ? null : (
              <div className="cost">
                {productData.cost} Bs
              </div>
            )}
          </div>
        </div>
        {productData.tagsValues && productData.tagsValues.length ? (
          <div className="mb-services">
            <div className="titleh6">Servicios:</div>
            <div className="list">
              {productData.tagsValues.map(o => (
                <span key={o}>{(
                  dataTagsServices.filter(x => (
                    o === x.value
                  ))[0] || {}
                ).label || o}</span>
              ))}
            </div>
          </div>
        ) : null}
        {productData.tagsAtencion && productData.tagsAtencion.length ? (
          <div className="mb-attention">
            <div className="titleh6">Atencion a:</div>
            <div className="list">
              {productData.tagsAtencion.map(o => (
                <span key={o}>{dataTagsAttention.filter(x => (
                  o === x.value
                ))[0].label}</span>
              ))}
            </div>
          </div>
        ) : null}
        <div
          className={'gallery-images'}
          options={{
            fitWidth: true,
          }}
        >
          {productData.images.map((imgId, imgIndex) => {
            const imgSrc = getImgSrc(imgId);
            return (
              <div
                className="img"
                key={imgId}
                onClick={onClickImage.bind(null, imgIndex)}
              >
                <img
                  key={imgId}
                  src={imgSrc}
                  alt="foto"
                />
              </div>
            );
          })}
        </div>
      </div>
      {currImage ? (
        <Modal
          appElement={document.getElementById('root')}
          isOpen={currImage != null}
          className="mb-preview-img"
          contentLabel="PreviewModal"
        >
          <div className="controls-top">
            <button
              onClick={onClickClose}
              className="close svg-button"
            >
              <svg x="0px" y="0px" viewBox="0 0 511.76 511.76">
                <path d="M436.896,74.869c-99.84-99.819-262.208-99.819-362.048,0c-99.797,99.819-99.797,262.229,0,362.048
                  c49.92,49.899,115.477,74.837,181.035,74.837s131.093-24.939,181.013-74.837C536.715,337.099,536.715,174.688,436.896,74.869z
                  M361.461,331.317c8.341,8.341,8.341,21.824,0,30.165c-4.16,4.16-9.621,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251
                  l-75.413-75.435l-75.392,75.413c-4.181,4.16-9.643,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251
                  c-8.341-8.341-8.341-21.845,0-30.165l75.392-75.413l-75.413-75.413c-8.341-8.341-8.341-21.845,0-30.165
                  c8.32-8.341,21.824-8.341,30.165,0l75.413,75.413l75.413-75.413c8.341-8.341,21.824-8.341,30.165,0
                  c8.341,8.32,8.341,21.824,0,30.165l-75.413,75.413L361.461,331.317z" />
              </svg>
            </button>
          </div>
          <div className="controls">
            <div className="controls-inner">
              {productData.images && productData.images.length > 1 ? (
                <button
                  onClick={onClickPrev}
                  className="img-prev svg-button"
                >
                  <svg x="0px" y="0px" viewBox="0 0 512 512">
                    <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M313.749,347.584
                      c8.341,8.341,8.341,21.824,0,30.165c-4.16,4.16-9.621,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251L176.917,271.083
                      c-8.341-8.341-8.341-21.824,0-30.165l106.667-106.667c8.341-8.341,21.824-8.341,30.165,0s8.341,21.824,0,30.165L222.165,256
                      L313.749,347.584z" />
                  </svg>
                </button>
              ) : null}
              {productData.images && productData.images.length > 1 ? (
                <button onClick={onClickNext} className="img-next svg-button">
                  <svg x="0px" y="0px" viewBox="0 0 512 512">
                    <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M335.083,271.083
                      L228.416,377.749c-4.16,4.16-9.621,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251c-8.341-8.341-8.341-21.824,0-30.165
                      L289.835,256l-91.584-91.584c-8.341-8.341-8.341-21.824,0-30.165s21.824-8.341,30.165,0l106.667,106.667
                      C343.424,249.259,343.424,262.741,335.083,271.083z" />
                  </svg>
                </button>
              ) : null}
            </div>
          </div>
          <div className="content">
            {productData.images && productData.images.length > 1 ? (
              <button onClick={onClickPrev} className="img-prev"></button>
            ) : null}
            <div
              className="img"
              key={currImage.imgId}
            >
              <img src={currImage.imgSrc} alt="foto" />
            </div>
            {productData.images && productData.images.length > 1 ? (
              <button onClick={onClickNext} className="img-next"></button>
            ) : null}
          </div>
        </Modal>
      ) : null}
    </div>
  )
}

export default (connect(
  ({ account, products }) => ({
    user: account.user,
    products: products.products,
  }),
  dispatch => bindActionCreators({
    getProduct,
    getCitiesList,
  }, dispatch)
)(Ad): any);
