/* @flow */

import Notification from 'rsuite/Notification';
import {
  requestGetCities,
  processSnapshotsArr,
} from './client';

const cache = {};
export function getCitiesList() {
  return (dispatch: Function) => {
    if (cache['Bo']) {
      return Promise.resolve(cache['Bo']);
    }
    return requestGetCities()
      .then((listSnapshot) => {
        cache['Bo'] = processSnapshotsArr(listSnapshot);
        return cache['Bo'];
      })
      .catch((err) => {
        console.error(err);
      });
  };
}
