/* @flow */

import { fabric } from 'fabric';

import {
  requestCreateProduct,
  uploadFirebaseImage,
  getImageUrl,
} from './client';

export const dataTagsAttention = [
  {
    value: 'men',
    label: 'Hombres',
    children: null,
    groupBy: '',
  }, {
    value: 'women',
    label: 'Mujeres',
    children: null,
    groupBy: '',
  }, {
    value: 'couples',
    label: 'Parejas',
    children: null,
    groupBy: '',
  }, {
    value: 'crippled',
    label: 'Discapacitados',
    children: null,
    groupBy: '',
  }, {
    value: 'shemales',
    label: 'Transexuales/Travestis',
    children: null,
    groupBy: '',
  }, {
    value: 'group',
    label: 'Grupo de Amigos',
    children: null,
    groupBy: '',
  }
];

export const dataTagsServices = [
  {
    value: 'oral',
    label: 'Sexo Oral',
    children: null,
    groupBy: '',
  }, {
    value: 'oralsin',
    label: 'Sexo Oral sin Condon',
    children: null,
    groupBy: '',
  }, {
    value: 'vaginal',
    label: 'Sexo Vaginal',
    children: null,
    groupBy: '',
  }, {
    value: 'strip',
    label: 'Estriptis',
    children: null,
    groupBy: '',
  }, {
    value: 'anal',
    label: 'Sexo Anal',
    children: null,
    groupBy: '',
  }, {
    value: 'outside',
    label: 'Salidas a Moteles',
    children: null,
    groupBy: '',
  }, {
    value: 'outsidehome',
    label: 'Salidas a Domicilio',
    children: null,
    groupBy: '',
  }, {
    value: 'owndep',
    label: 'Lugar Propio',
    children: null,
    groupBy: '',
  }, {
    value: 'sumi',
    label: 'Sumisa',
    children: null,
    groupBy: '',
  }, {
    value: 'domi',
    label: 'Dominante',
    children: null,
    groupBy: '',
  }, {
    value: 'bdsm',
    label: 'BDSM',
    children: null,
    groupBy: '',
  }, {
    value: 'videocall',
    label: 'Video Llamadas',
    children: null,
    groupBy: '',
  }, {
    value: 'call',
    label: 'Llamadas Hot',
    children: null,
    groupBy: '',
  }, {
    value: 'packs',
    label: 'Venta de Packs hot',
    children: null,
    groupBy: '',
  }, {
    value: 'tratonovios',
    label: 'Trato de novios',
    children: null,
    groupBy: '',
  }, {
    value: 'eyaculacioncorporal',
    label: 'Eyaculación corporal',
    children: null,
    groupBy: '',
  }, {
    value: 'massagehot',
    label: 'Masaje erótico',
    children: null,
    groupBy: '',
  }, {
    value: 'fetish',
    label: 'Fetiches',
    children: null,
    groupBy: '',
  }
];

export function generateImageUrl() {
  return getImageUrl();
}

export function uploadImage(file: Object, props: Object) {
  return uploadFirebaseImage(file, props);
}

export function createProduct(propsRaw: Object) {
  const props = {
    ...propsRaw,
    date: new Date(),
  };
  return requestCreateProduct(props).catch((err) => {
    console.error(err);
  });
}

export function handleUploadImageFile(
  image:Object,
  progressFn:Function = () => {}
): Promise<any> {
  return new Promise((resolve) => {
    function fnUploadImage(fileImage:Object) {
      const preview = URL.createObjectURL(fileImage);
      uploadImage(fileImage, {
        folder: 'original',
        progressFn,
      }).then((newIdImage) => {
        resolve({
          id: newIdImage,
          image: fileImage,
          preview,
        });
      });
    }
    const canvas = new fabric.Canvas('canvasupload', {
      imageSmoothingEnabled: false,
      enableRetinaScaling: false,
    });
    fabric.Image.fromURL(URL.createObjectURL(image), (img) => {
      let imgWidth = img.width;
      let imgHeight = img.height;
      if (imgWidth <= 1200 && imgHeight <= 1200) {
        fnUploadImage(image);
      } else {
        const ratioSize = Math.min(1200 / imgWidth, 1200 / imgHeight);
        img.set({
          top: 0,
          left: 0,
          width: imgWidth,
          height: imgHeight,
          scaleX: ratioSize,
          scaleY: ratioSize,
        });
        window.img = img;
        canvas.setWidth(img.getScaledWidth());
        canvas.setHeight(img.getScaledHeight());
        canvas.add(img);
        canvas.renderAll();
        canvas.lowerCanvasEl.toBlob((canvasBlob) => {
          fnUploadImage(canvasBlob);
        }, 'image/jpeg', 0.95);
      }
    });
  });
}
