/* @flow */

import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import debounce from 'lodash/debounce';

import Dropdown from 'rsuite/Dropdown';
import Carousel from 'rsuite/Carousel';
import Button from 'rsuite/Button';
import Loader from 'rsuite/Loader';

import { useNavigate } from 'react-router-dom';

import {
  getProductsList,
} from '../actions/products';
import {
  getCategoriesList,
} from '../actions/categories';
import {
  getCitiesList,
} from '../actions/cities';
import {
  formatDateToSpanish,
} from '../localized';

import './Home.css';

// peladingas.com
// partyanuncios.com
// 

function Home(props: Object):Object {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [categories, setCategories]:[Array<any>, Function] = useState([]);
  const [cities, setCities]:[Array<any>, Function] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState('c1');
  const [cityFilter, setCityFilter] = useState(null);
  const [firstLoad, setFirstLoad] = useState(false);

  const products = useSelector(state => state.products?.products);
  function loadProductList(
    category:any,
    city:any,
    date:Object = null
  ) {
    return dispatch(getProductsList(
      category,
      city,
      date
    )).then((res) => {
    });
  }
  useEffect(() => {
    loadProductList(categoryFilter, cityFilter).then(() => {
      setFirstLoad(true);
    });
    dispatch(getCategoriesList()).then((res) => {
      setCategories(res);
    });
    dispatch(getCitiesList()).then((res) => {
      setCities(res);
    });
  }, []);

  useEffect(() => {
    const container = document.getElementsByClassName('mainscroll')[0];
    const onScroll = debounce(() => {
      var scrollY = container.scrollHeight - container.scrollTop;
      var height = container.offsetHeight;
      var offset = height - scrollY;

      if (offset === 0 || offset === 1) {
        if (products && products.length && products[products.length - 1]) {
          loadProductList(
            categoryFilter,
            cityFilter,
            products[products.length - 1].data.date
          );
        }
      }
    }, 100);

    container.addEventListener('scroll', onScroll);
    return () => {
      container.removeEventListener('scroll', onScroll);
    };
  }, [products.length && products[products.length - 1]]);

  const onClickCategory = (value:Object) => {
    setCategoryFilter(value.id);
    loadProductList(value.id, cityFilter);
  };
  const onClickCity = (value:Object) => {
    setCityFilter(value.id);
    loadProductList(categoryFilter, value.id);
  };
  const onClickAd = (
    categoryId: string,
    adId: string
  ) => {
    navigate(`/ad/${adId}`);
  };

  let cityFilterText = 'Bolivia';
  if (cityFilter) {
    cities.forEach((c) => {
      if (c.id === cityFilter) {
        cityFilterText = c.data.name;
      }
    });
  }
  let categoryFilterText = 'Categoria';
  if (categoryFilter) {
    categories.forEach((c) => {
      if (c.id === categoryFilter) {
        categoryFilterText = c.data.name;
      }
    });
  }
  let lastDateStr = '';

  return (
    <div className="mb-home">
      <div className="mb-filters">
        <Dropdown
          className="category-filter"
          title={categoryFilterText}
          appearance="default"
          searchable="false"
        >
          {categories.map((c) => (
            <Dropdown.Item
              key={c.id}
              onClick={onClickCategory.bind(null, c)}
              style={{ width: 200 }}
            >
              {c.data.name}
            </Dropdown.Item>
          ))}
        </Dropdown>
        <Dropdown
          className="city-filter"
          title={cityFilterText}
          appearance="default"
          searchable="false"
        >
          {cities.map((c) => (
            <Dropdown.Item
              key={c.id}
              onClick={onClickCity.bind(null, c)}
              style={{ width: 200 }}
            >
              {c.data.name}
            </Dropdown.Item>
          ))}
        </Dropdown>
      </div>
      {firstLoad ? (
        products && products.length ? (
          <div className="mb-list">
            {products.map((p) => {
              const bottomItems:Array<Object> = [];
              const addItem = (item:any, extraText:any) => {
                if (item) {
                  if (bottomItems.length) {
                    bottomItems.push(<span key={bottomItems.length}> | </span>);
                  }
                  bottomItems.push(<span key={bottomItems.length}>{item} {extraText}</span>);
                }
              };
              addItem(p.data.years, 'Años');
              cities.forEach((c) => {
                if (c.id === p.data.city) {
                  addItem(c.data.name);
                }
              });
              addItem(p.data.zone);
              let dateDivCmp = null;
              const auxDateStr = formatDateToSpanish(new Date(p.data.date));
              if (lastDateStr !== auxDateStr) {
                dateDivCmp = (
                  <div
                    style={{ marginLeft: 10, marginTop: 20 }}
                    key={`date${p.data.date.getTime()}`}
                  >
                    {auxDateStr}
                  </div>
                );
                lastDateStr = auxDateStr;
              }
              return [
                dateDivCmp,
                <div
                  key={p.id}
                  className="mb-preview-item"
                  onClick={onClickAd.bind(null, p.data.category, p.id)}
                >
                  {p.data.images && p.data.images.length ? (
                    <Carousel
                      className="previews-slider"
                      shape="bar"
                      autoplay
                    >
                      {p.data.images.map((imgId) => (
                        <img
                          key={imgId}
                          src={`https://firebasestorage.googleapis.com/v0/b/marketbol.appspot.com/o/250x250%2Fthumb_${imgId}?alt=media`}
                          alt="foto"
                        />
                      ))}
                    </Carousel>
                  ) : null}
                  <div className="content">
                    <h2 className="title">{p.data.title}</h2>
                    <p
                      className="desc"
                      dangerouslySetInnerHTML={{ __html: `${(p.data.desc || '').slice(0, 100)}...` }}
                    ></p>
                    <div className="bottom">
                      {bottomItems}
                    </div>
                  </div>
                </div>,
              ];
            })}
          </div>
        ) : (
          <div className="mb-center">
            No existen anuncios para esta busqueda
          </div>
        )
      ) : (
        <div className="mb-center">
          <Loader size="lg" content="" />
        </div>
      )}
    </div>
  );
}

export default Home;
