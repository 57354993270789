/* @flow */

import c from '../constants';

const defaultState = {
  user: null,
};

export default function (state: Object = defaultState, action: Object = {}): Object {
  switch (action.type) {
    case c.SET_USER:
      return { ...state, user: action.payload };
    case c.UPDATE_USER:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case c.SIGN_OUT:
      return {
        ...state,
        user: null,
      };
    default:
      break;
  }
  return state;
}
