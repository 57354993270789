/* @flow */

import React, { Fragment, Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  HashRouter,
  Routes,
  Route,
  Navigate,
  useRoutes,
} from 'react-router-dom';

import { registerAuthStateChanged } from './actions/account';

import Enter from './components/Enter';
import Account from './components/Account';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Parties from './components/Parties';
import Ad from './components/Ad';

const CreateProduct = React.lazy(() => import('./components/CreateProduct'));
const CreateGlobalAd = React.lazy(() => import('./components/CreateGlobalAd'));
const Statistics = React.lazy(() => import('./components/Statistics'));
const EditProduct = React.lazy(() => import('./components/EditProduct'));

function App(props: Object) {
  props.registerAuthStateChanged();

  let element = useRoutes([
    { path: "/create-ad", element: <CreateProduct /> },
    { path: "/create-global-ad", element: <CreateGlobalAd /> },
    { path: "/parties", element: <Parties /> },
    { path: "/statistics", element: <Statistics /> },
    { path: "/enter", element: <Enter /> },
    { path: "/account", element: <Account /> },
    { path: "/edit-ad/:productId", element: <EditProduct /> },
    { path: "/ad/:productId", element: <Ad /> },
    {
      path: "/",
      element: <Home />,
    },
  ]);

  return (
    <Fragment>
      <Header />
      <Suspense fallback={<div></div>}>
        <div className="mainscroll">
          {element}
        </div>
      </Suspense>
      <Footer />
    </Fragment>
  );
}

export default (connect(
  ({ account }) => ({
    user: account.user,
  }),
  dispatch => bindActionCreators({
    registerAuthStateChanged,
  }, dispatch)
)(App): any);
