/* @flow */

import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';

import rootReducer from '../reducers';

// import { createLogger } from 'redux-logger';
// import { browserHistory } from 'react-router';
// import { routerMiddleware } from 'react-router-redux';

const middlewares = [
  // routerMiddleware(browserHistory),
  thunk,
];
if (process.env.NODE_ENV !== 'production') {
  // const logger = createLogger({ collapsed: true });
  // middlewares.push(logger);
}

const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);

export function configureStore(initialState) {
  const store = createStoreWithMiddleware(rootReducer, initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : undefined);
  if (module.hot && process.env.NODE_ENV !== 'production') {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
}
