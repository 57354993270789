/* @flow */

import { combineReducers } from 'redux';
// import { routerReducer } from 'react-router-redux';

import account from './account';
import products from './products';

const rootReducer = combineReducers({
  account,
  products,
});

export default rootReducer;