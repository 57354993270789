/* @flow */

import React, { useState, useEffect, Fragment } from "react";
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Dropdown from 'rsuite/Dropdown';
import Sidenav from 'rsuite/Sidenav';
import Navbar from 'rsuite/Navbar';
import Nav from 'rsuite/Nav';
import Avatar from 'rsuite/Avatar';
import Animation from 'rsuite/Animation';

import UserIcon from '@rsuite/icons/legacy/User';
import MenuIcon from '@rsuite/icons/Menu';
import DashboardIcon from '@rsuite/icons/Dashboard';

import {
  useNavigate,
  useLocation,
  NavLink,
} from 'react-router-dom';

import './Header.css';

function Header(props: Object) {
  const [show, setShow] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const onMobileMenuSelected = (eventKey:string) => {
    navigate(eventKey);
    setShow(false);
  };

  if (window.mobileCheck()) {
    return [
      <div className="mb-header-mobile">
        Party Anuncios
      </div>,
      show ? (
        <span
          className="openclosebg"
          onClick={() => { setShow(!show); }}
        ></span>
      ) : null,
      <div
        className="mb-header-menu-mobile"
        style={{ left: show ? 0 : -240 }}
      >
        {!show ? (
          <a
            className="openclosebtn"
            onClick={() => { setShow(!show); }}
          >
            <MenuIcon />
          </a>
        ) : null}
        <Sidenav defaultOpenKeys={['/']}>
          <Sidenav.Body>
            <Nav
              activeKey={location.pathname}
              onSelect={onMobileMenuSelected}
            >
              <Nav.Item
                eventKey="/"
                icon={<DashboardIcon />}
              >
                {window.lc('Home')}
              </Nav.Item>
              <Nav.Item eventKey="/parties">
                {window.lc('Parties')}
              </Nav.Item>
              {props.user ? (
                <Nav.Item eventKey="/create-ad">
                  {window.lc('Create Ad')}
                </Nav.Item>
              ) : null}
              {props.user && process.env.NODE_ENV === 'development' ? (
                <Nav.Item eventKey="/create-global-ad">
                  {window.lc('Create Party')}
                </Nav.Item>
              ) : null}
              {props.user ? (
                <Nav.Item eventKey="/statistics">
                  {window.lc('Statistics')}
                </Nav.Item>
              ) : null}
              {props.user ? (
                <Nav.Item
                  eventKey="/account"
                  icon={<UserIcon />}
                >
                  {window.lc('Account')}
                </Nav.Item>
              ) : (
                <Nav.Item eventKey="/enter">
                  {window.lc('Enter')}
                </Nav.Item>
              )}
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </div>,
    ];
  }

  return (
    <div
      className={classNames('mb-header', {})}
    >
      <Navbar>
        <Navbar.Brand>
        </Navbar.Brand>
        <Nav>
          <NavLink strict="true" to="/">
            {window.lc('Home')}
          </NavLink>
          <NavLink strict="true" to="/parties">
            {window.lc('Parties')}
          </NavLink>
          {props.user ? (
            <NavLink strict="true" to="/create-ad">
              {window.lc('Create Ad')}
            </NavLink>
          ) : null}
          {props.user && process.env.NODE_ENV === 'development' ? (
            <NavLink strict="true" to="/create-global-ad">
              {window.lc('Create Party')}
            </NavLink>
          ) : null}
          {props.user ? (
            <NavLink strict="true" to="/statistics">
              {window.lc('Statistics')}
            </NavLink>
          ) : null}
        </Nav>

        <Nav pullRight>
          {props.user ? (
            <NavLink
              strict="true"
              to="/account"
              className="mb-account-link"
            >
              <span style={{ display: 'block' }}>
                {window.lc('Account')}
              </span>
              <Avatar>
                <UserIcon />
              </Avatar>
            </NavLink>
          ) : (
            <NavLink strict="true" to="/enter">
              {window.lc('Enter')}
            </NavLink>
          )}
        </Nav>
      </Navbar>
    </div>
  );
}

export default (connect(
  ({ account }) => ({
    user: account.user,
  }),
  dispatch => bindActionCreators({
  }, dispatch)
)(Header): any);
