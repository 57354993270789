/* @flow */

import c from '../constants';

const defaultState = {
  products_stats: [],
  products: [],
  curr: {},
};

export default function (state: Object = defaultState, action: Object = {}): Object {
  switch (action.type) {
    case c.UPDATE_PRODUCTS: {
      if (action.payload.add) {
        return {
          ...state,
          products: [
            ...state.products,
            ...action.payload.products,
          ],
        };
      }
      return {
        ...state,
        products: action.payload.products,
      };
    }
    case c.UPDATE_PRODUCTS_STATS: {
      if (action.payload.add) {
        return {
          ...state,
          products_stats: [
            ...state.products_stats,
            ...action.payload.products,
          ],
        };
      }
      return {
        ...state,
        products_stats: action.payload.products,
      };
    }
    default:
      break;
  }
  return state;
}
