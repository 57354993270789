/* @flow */

import React from 'react';
import Footer from 'rsuite/Footer';

function FooterCmp(props:Object):Object {
  return (
    <Footer>
      © 2024 Party Anuncios. Todos los derechos reservados.
    </Footer>
  );
}

export default FooterCmp;
