/* @flow */

import Notification from 'rsuite/Notification';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOutAuth,
  requestSaveAuthorData,
} from './client';

import c from '../constants';

function onError(error:Object) {
  Notification['error']({
    title: 'Error',
    description: error.message,
  });
}

let authStateChangedRegistered = false;
export function registerAuthStateChanged():Function {
  return (dispatch: Function) => {
    if (authStateChangedRegistered) return;
    authStateChangedRegistered = true;
    onAuthStateChanged((userRes) => {
      dispatch({
        type: c.SET_USER,
        payload: userRes,
      });
    });
  };
}

export function createEmailPass(email: string, password: string):Promise<any> {
  return createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
      console.log(userCredential);
      return userCredential;
    });
}

export function signInEmailPass(email:string, password:string):Promise<any> {
  return signInWithEmailAndPassword(email, password);
}

export function saveAccountData(userUid:string, data:Object):Function {
  return (dispatch: Function) => {
    return requestSaveAuthorData(userUid, data).then(() => {
      dispatch({
        type: c.UPDATE_USER,
        payload: data,
      });
    });
  };
}

export function signOut():Function {
  return (dispatch: Function) => {
    signOutAuth();
    dispatch({
      type: c.SIGN_OUT,
    });
  };
}
