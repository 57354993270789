/* @flow */

import Notification from 'rsuite/Notification';
import {
  requestGetCategories,
  processSnapshotsArr,
} from './client';

export function getCategoriesList() {
  return (dispatch: Function) => {
    return requestGetCategories()
      .then((listSnapshot) => {
        return processSnapshotsArr(listSnapshot);
      })
      .catch((err) => {
        console.error(err);
      });
  };
}
